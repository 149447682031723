<template>
  <b-card no-body style="border-radius: 8px">
    <b-row class="pt-1">
      <b-col cols="3">
        <div class="icon-container">
          <slot name="icon"></slot>
        </div>
      </b-col>
      <b-col cols="9">
        <div
          :class="{ card: false, pointer: onClick !== null }"
          @click="cardClicked"
        >
          <div class="title">
            {{ title }}
          </div>
          <div class="value" :style="{ color: color }">
            <p>{{ normalizeProductNumber(value, 2) }}</p>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { normalizeProductNumber } from '@/utils/product-helper'

export default {
  props: {
    title: { type: String, required: true },
    value: [Number, String],
    path: String,
    color: String,
    onClick: Function
  },
  methods: {
    normalizeProductNumber,
    cardClicked() {
      this.onClick && this.onClick(this.path)
    }
  }
}
</script>
<style lang="scss" scoped>
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  background-color: var(--neutral-100);
  border-radius: 8px;
  .icon {
    width: 24px;
    height: 24px;
  }
}
.card {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  height: 100%;
  text-align: left;
  justify-content: center;
  padding: 8px 18px;

  .title {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: var(--dark-blue);
    margin-bottom: 0 !important;
  }

  .value {
    display: flex;
    flex-direction: row;
    gap: 8px;
    font-family: 'Red Hat Display';
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;

    .value-prefix {
      font-size: 18px;
      font-weight: 700;
    }

    .tag {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 8px;
      gap: 4px;
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 130%;
      border-radius: 16px;
      &.blue {
        background-color: var(--blue-500);
        color: var(--neutral-000);
      }
      &.yellow {
        background-color: var(--yellow);
        color: var(--neutral-600);
      }
      &.green {
        background-color: #58d19e;
        color: var(--neutral-000);
      }
      &.red {
        background-color: var(--states-error);
        color: var(--neutral-000);
      }
    }
  }

  @media (min-width: 1440px) {
    .value {
      font-size: 32px !important;
    }
  }

  @media (max-width: 1439px) {
    .value {
      font-size: 25px !important;
    }
  }
}
.pointer {
  cursor: pointer;
}
</style>
