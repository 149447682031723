import { render, staticRenderFns } from "./IconSimpleCard.vue?vue&type=template&id=24bf3905&scoped=true"
import script from "./IconSimpleCard.vue?vue&type=script&lang=js"
export * from "./IconSimpleCard.vue?vue&type=script&lang=js"
import style0 from "./IconSimpleCard.vue?vue&type=style&index=0&id=24bf3905&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24bf3905",
  null
  
)

export default component.exports